import { subject } from '@casl/ability';
import { createGuideMaterial, reorderGuideMaterialsV2 } from 'api/guide';
import { useGetGuideMaterialCategories } from 'api/guideMaterialCategoryHooks';
import { useInvalidateAnyGuide, useInvalidateMaterialLike } from 'api/invalidate';
import { Can, actions, subjectArea } from 'casl/setupCaslAbility';
import EmptyIndicator from 'components/EmptyIndicator';
import InfoBox from 'components/InfoBox';
import ListItemAdd from 'components/ListItemAdd';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import { UpdateSortGuideMaterialsV2DTO } from 'dto/guide';
import { observer } from 'mobx-react';
import { GuideMaterialList, buildNewGuideMaterial, filterCategory } from 'modules/guideMaterial';
import { CategoryMaterialList } from 'modules/guideMaterial/CategoryMaterialList';
import { OnGuideMaterialClick } from 'modules/guideMaterial/guideMaterialListContext';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyMaterial } from 'stores/searchStore';
import { useStores } from 'util/mobx/stores';
import GuideMaterialRightMenu from './GuideMaterialRightMenu';

interface AddGuideMaterialProps extends OptionalLazyLoadProps {
  guideId: string;
  guideMaterialCategoryId: string;
}

function useAddGuideMaterial(guideId: string, guideMaterialCategoryId?: string) {
  const { searchStore } = useStores();

  const { t: searchTranslation } = useTranslation('materialSearch');
  const invalidateGuide = useInvalidateAnyGuide();

  const handleCreateMaterial = useCallback(
    async (material: AnyMaterial) => {
      if (!guideMaterialCategoryId) {
        throw new Error('no guideMaterialCategoryId');
      }
      const newGuideMaterial = buildNewGuideMaterial(guideId, material, guideMaterialCategoryId);
      if (!newGuideMaterial) {
        return;
      }

      await createGuideMaterial(newGuideMaterial);
      await invalidateGuide();
    },
    [guideId, guideMaterialCategoryId, invalidateGuide]
  );

  const handleAddMaterial = useCallback(() => {
    searchStore.openSearchPanel(handleCreateMaterial, searchTranslation('searchItem.addItem'));
  }, [handleCreateMaterial, searchStore, searchTranslation]);

  return handleAddMaterial;
}

const AddGuideMaterial: React.FC<AddGuideMaterialProps> = observer(({ guideId, guideMaterialCategoryId }) => {
  const { guideStore } = useStores();

  const { t } = useTranslation('guideMaterials');

  const handleAddMaterial = useAddGuideMaterial(guideId, guideMaterialCategoryId);

  return (
    <Can I={actions.add} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
      <ListItemAdd className="list_item_material_add" onClick={handleAddMaterial}>
        <InfoBox label={t('button.addMaterial')} />
      </ListItemAdd>
    </Can>
  );
});

interface Props extends OptionalLazyLoadProps {
  guideId: string;
}

export const GuideMaterialsV2 = observer(({ lazyLoadScrollContainer, guideId }: Props) => {
  const { t } = useTranslation('guideMaterials');
  const { guideDetailDrawerStore, appNavigationStore, loadingStore, domainStore, guideStore } = useStores();
  const invalidateGuide = useInvalidateAnyGuide();
  const invalidateMaterials = useInvalidateMaterialLike();

  const { data: groupedGuideMaterialCategories } = useGetGuideMaterialCategories({});

  // Map the correct functional area out of the result.
  const guideMaterialCategories = useMemo(() => {
    return filterCategory(domainStore.currentFunctionalArea.id, groupedGuideMaterialCategories);
  }, [domainStore.currentFunctionalArea.id, groupedGuideMaterialCategories]);

  const handleAddMaterial = useAddGuideMaterial(guideId, guideMaterialCategories && guideMaterialCategories[0]?.guideMaterialCategoryId);

  useEffect(() => {
    guideDetailDrawerStore.enableSettings();
    return () => {
      guideDetailDrawerStore.disableSettings();
    };
  });

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <GuideMaterialRightMenu onAddMaterial={handleAddMaterial} />;
    });
  }, [appNavigationStore, handleAddMaterial]);

  appNavigationStore.useSubPageIdSetter('materials');

  const handleGuideMaterialClick: OnGuideMaterialClick = useCallback(
    (guideMaterial, specific) => {
      guideDetailDrawerStore.openWithGuideMaterial(guideMaterial.guideMaterialId, specific, false, true);
    },
    [guideDetailDrawerStore]
  );

  const handleGuideMaterialsReload = useCallback(async () => {
    await Promise.all([invalidateGuide(), invalidateMaterials()]);
  }, [invalidateGuide, invalidateMaterials]);

  const handleGuideMaterialReorder = useCallback(
    async (reorder: UpdateSortGuideMaterialsV2DTO) => {
      await loadingStore.withLoadingBar(async () => {
        await reorderGuideMaterialsV2(reorder);
      });
    },
    [loadingStore]
  );

  const handleDeleteGuideMaterial = useCallback(
    async (guideMaterialId: string): Promise<void> => {
      if (guideStore.selectedGuide) {
        await guideStore.deleteGuideMaterial({ guideMaterialId }, guideStore.selectedGuide.guideId);
        await handleGuideMaterialsReload();
      }
    },
    [guideStore, handleGuideMaterialsReload]
  );

  const categoriesExist = guideMaterialCategories && guideMaterialCategories.length > 0;

  return (
    <EmptyIndicator isEmpty={!categoriesExist} message={t('noGuideMaterialCategoriesMessage')}>
      <div className="single_colum_content">
        <GuideMaterialList
          CategoryMaterialListElement={CategoryMaterialList}
          CategoryPrefix={AddGuideMaterial}
          functionalAreaId={domainStore.currentFunctionalArea.id}
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          onGuideMaterialClick={handleGuideMaterialClick}
          onReorder={handleGuideMaterialReorder}
          onReload={handleGuideMaterialsReload}
          onDelete={handleDeleteGuideMaterial}
          guideLikeId={guideId}
        />
      </div>
    </EmptyIndicator>
  );
});
