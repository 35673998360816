import { updateMaterial } from 'api/material';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { MaterialDTO } from 'dto/material';
import React, { useCallback } from 'react';
import { useStores } from 'util/mobx/stores';
import { useGuideMaterialListContext } from '../../guideMaterialListContext';
import { GuideMaterialListItem } from '../GuideMaterialListItem';
import { ItemPicture } from '../../../material/ListItems';

interface Props {
  material: MaterialDTO;
  guideMaterial: GuideMaterialLikeFlatDTO;
  lazyLoadScrollContainer?: string;
  level?: number;
  noAmount?: boolean;
  onClick: () => void;
  onDelete?: () => void;
  onAddLotNumber?: () => void;
  prefix?: React.ReactElement;
  instructions?: React.ReactNode;
}

export const MaterialItem = ({
  material,
  guideMaterial,
  onClick,
  lazyLoadScrollContainer,
  level = 0,
  noAmount = false,
  onDelete,
  onAddLotNumber,
  prefix,
  instructions
}: Props) => {
  const { loadingStore } = useStores();
  const { onReload } = useGuideMaterialListContext();

  const handleUpdateFile = useCallback(
    async (fileId: string) => {
      if (!onReload) {
        return;
      }
      await loadingStore.withLoadingBar(() => updateMaterial({ materialId: material.materialId, pictureFileId: fileId }));
      onReload();
    },
    [loadingStore, material.materialId, onReload]
  );

  return (
    <GuideMaterialListItem
      level={level}
      amount={noAmount ? undefined : guideMaterial.amount}
      name={material.name}
      picture={
        <ItemPicture
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          picture={material}
          needsStorageLocation={!guideMaterial.storageLocationId}
          onUpdateFile={handleUpdateFile}
        />
      }
      onClick={onClick}
      instructions={instructions}
      end={<MaterialValueTags material={material} />}
      prefix={prefix}
      onDelete={onDelete}
      onAddLotNumber={onAddLotNumber}
      onOpenKnowledgeAndAttitudes={onClick}
    />
  );
};
