import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { PackagingType } from 'dto/package';
import React, { useCallback } from 'react';
import Instructions from 'components/Instructions';
import { GuideMaterialListItemRenderer } from '../ListItems/GuideMaterialListItem';
import { SingleTemplateItem } from '../ListItems/Instrument/SingleTemplate';
import { TemplateItem } from '../ListItems/Instrument/TemplateItem';
import { MaterialItem } from '../ListItems/Material/MaterialItem';
import { MaterialSetItem } from '../ListItems/MaterialSet/MaterialSetItem';
import { useGuideMaterialListContext } from '../guideMaterialListContext';

interface GuideMaterialLikeItemProps {
  guideMaterial: GuideMaterialLikeFlatDTO;
}

export const GuideMaterialLikeItem: React.FC<GuideMaterialLikeItemProps> = ({ guideMaterial }) => {
  const { lazyLoadScrollContainer, onDelete, onGuideMaterialClick } = useGuideMaterialListContext();

  const handleClickMaterial = useCallback(() => {
    if (!onGuideMaterialClick) {
      return;
    }
    onGuideMaterialClick(guideMaterial);
  }, [guideMaterial, onGuideMaterialClick]);

  const handleClickTemplate = useCallback(
    (
      parent: GuideMaterialLikeFlatDTO,
      id: {
        packageId?: string | undefined;
        materialId?: string | undefined;
      }
    ) => {
      if (!onGuideMaterialClick) {
        return;
      }
      onGuideMaterialClick(parent, { packageId: id.packageId, materialId: id.materialId });
    },
    [onGuideMaterialClick]
  );

  const handleDelete = useCallback(async () => {
    if (!onDelete) {
      return;
    }

    await onDelete(guideMaterial.guideMaterialId);
  }, [guideMaterial.guideMaterialId, onDelete]);

  let item = <>UNKNOWN</>;

  if (guideMaterial.material) {
    item = (
      <MaterialItem
        material={guideMaterial.material}
        guideMaterial={guideMaterial}
        onClick={handleClickMaterial}
        lazyLoadScrollContainer={lazyLoadScrollContainer}
        onDelete={handleDelete}
        instructions={<Instructions notes={guideMaterial.notes} />}
      />
    );
  }

  if (guideMaterial.template && guideMaterial.template.packagingType === PackagingType.Multi) {
    item = <TemplateItem guideMaterial={guideMaterial} onClick={handleClickTemplate} ListItemRenderer={GuideMaterialListItemRenderer} />;
  }

  if (guideMaterial.template && guideMaterial.template.packagingType === PackagingType.Single) {
    item = (
      <SingleTemplateItem onClick={handleClickTemplate} ListItemRenderer={GuideMaterialListItemRenderer} guideMaterial={guideMaterial} />
    );
  }

  if (guideMaterial.materialSet) {
    item = <MaterialSetItem guideMaterial={guideMaterial} onClick={handleClickMaterial} onDelete={handleDelete} />;
  }

  return <div>{item}</div>;
};
